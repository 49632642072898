import React from "react"
import Layout from "../components/organisms/layout"
import SEO from "../components/seo"
import Wrapper from "../components/atoms/page-wrapper"
import { graphql } from "gatsby"
import Heading from "../components/atoms/newsletter-heading"
import Img from "gatsby-image"

const CertificatesWrapper = ({ data }) => (
  <Layout
    cert1={data.cert1.childImageSharp.fluid}
    cert2={data.cert2.childImageSharp.fluid}
  >
    <SEO title="Certyfikaty" />
    <Wrapper noImg>
      <Heading>Certyfikaty i testy od Cambridge</Heading>
      <Img
        fluid={data.placeholderImage.childImageSharp.fluid}
        alt={"Świadczone kwalifikacje i testy"}
      />
    </Wrapper>
  </Layout>
)

export const query = graphql`
  query {
    placeholderImage: file(relativePath: { eq: "CEFR.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    cert1: file(relativePath: { eq: "cambridge-english-assessment.png" }) {
      childImageSharp {
        fluid(maxWidth: 384, maxHeight: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    cert2: file(relativePath: { eq: "direct-method.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 244, maxHeight: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

export default CertificatesWrapper
